import { deleteAllCookies } from "./cookies";

export const interceptors = (status: number, type?: string) => {
  if (status === 401) {
    deleteAllCookies();
    window.location.href = `${process.env.BASE_URL}/login/signup`;
  }

  if (status === 400 && type === "auth") {
    deleteAllCookies();
    window.location.href = `${process.env.BASE_URL}/login/signup`;
  }
};
